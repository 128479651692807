import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    Checkbox,
    Container,
    FormGroup,
    FormControlLabel,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import httpClient from '../httpClient';

const BLANK_PERMISSION_STRING = "none";

const InviteUserView = (props) => {

    const [assets, setAssets] = React.useState([]);
    const [permissionList, setPermissionList] = React.useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        httpClient.get(
            '/organization/assets/' + props.user.organization._id
        ).then((response) => {
            setAssets(response.data.assets);
        }).catch((reason) => {
            console.log("Error getting assets:");
            console.log(reason);
        });
    }, [props.user.organization._id]);

    const validationSchema = yup.object({
        email: yup.string().email().required(),
        // role: yup.string().required(),
    });

    const handleSubmit = (values) => {
        httpClient.post(
            "/organization/add-new-user/" + props.user.organization._id,
            {
                email: values.email,
                username: BLANK_PERMISSION_STRING,
                password: BLANK_PERMISSION_STRING,
                fullName: BLANK_PERMISSION_STRING,
                isVerified: false,
                permissionList: permissionList
                // role: values.role,
            }
        ).then((response) => {
            // show confirmation
            if (response.data.message === "OK") {
                alert("User invited successfully.");
            } else {
                alert(response.data.message);
            }
            navigate('/orgmanage');
        }).catch((reason) => {
            console.log("Error inviting user:");
            console.log(reason);
            alert(
                "There was a problem inviting the user. " +
                "Please refresh the page and try again."
            );
        });
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            // role: undefined,
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    return (
        <Container>
            <form onSubmit={formik.handleSubmit}>
                <Typography variant="h4" gutterBottom>
                    Invite User
                </Typography>
                <TextField
                    id="email"
                    name="email"
                    label="Send invite to email address"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <Typography variant='h6' gutterBottom>Asset Permissions</Typography>

                <Stack spacing={2}>
                    {assets.map((asset) => (
                        <Card elevation={4}>
                            <Typography variant="h6" gutterBottom>
                                {asset.name}
                            </Typography>
                            {/* Checkboxes for read and write access */}
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Read"
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Write"
                                />
                            </FormGroup>

                        </Card>
                    ))}
                </Stack>

                <Button sx={{marginTop: '16px'}} variant='contained' type='submit' color='primary'>Invite User</Button>

            </form>
        </Container>
    )
}

export default InviteUserView