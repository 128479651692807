import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import httpClient from '../../httpClient';
import {
    Button,
    Container,
    Grid,
    Select,
    Typography
} from '@mui/material';

const PricingForm = (props) => {
    const [prices, setPrices] = useState([]);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [numDevices, setNumDevices] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPrices = async () => {
            httpClient.get('/stripe/price-details').then(response => {
                const responsePrices = response.data.data;
                setPrices(responsePrices);
            }).catch(err => console.error(err));
        };
        fetchPrices();

        const fetchNumDevices = async () => {
            httpClient.get('/organization/devices/current').then(response => {
                const deviceCount = response.data.devices.length;
                setNumDevices(deviceCount);
            }).catch(err => console.error(err));
        };
        fetchNumDevices();
    }, [])

    const createSubscription = async (priceId) => {
        return httpClient.post(
            '/stripe/subscription',
            {
                priceId: priceId,
            }
        ).then((response) => {
            const { subscriptionId, clientSecret } = response.data;
            setSubscriptionData({
                subscriptionId: subscriptionId,
                clientSecret: clientSecret
            });
            // Redirect to the subscription page
            // Navigate('/subscribe', { state: { subscriptionId, clientSecret } })
            setShouldRedirect(true);

        }).catch((err) => {
            console.log("Error creating subscription:");
            console.error(err);
        });

    }

    const handlePaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
    }

    // if (subscriptionData) {
    //     return <Navigate to={{
    //         pathname: '/subscribe',
    //         state: subscriptionData
    //     }} />
    // }

    if (!prices.length) {
        return <p>No price objects found</p>
    }

    if (shouldRedirect) {
        console.log('Redirecting to /subscribe');
        console.log(subscriptionData);
        navigate('/subscribe', { state: subscriptionData });
        // alert('Redirecting to /subscribe');
    }

    return (
        <>
            <Typography gutterBottom variant='h4'>Select a plan</Typography>
            <Container>

                <div className="price-list">
                    {prices.map((price) => {
                        return (
                            <div key={price.id}>
                                <h4>{price.nickname}</h4>

                                <Typography variant='body1' gutterBottom>
                                    ${price.unit_amount / 100} / hubodometer / year
                                </Typography>

                                {
                                    numDevices === null ? (<Typography variant='body1'>Loading...</Typography>) : (
                                        <Typography variant='body1'>
                                            Price for {numDevices} hubodometers currently in account: ${price.unit_amount / 100 * numDevices} / year
                                        </Typography>
                                    )

                                }

                                {/* <h6>Selected Payment Method:</h6>
                                <Grid container spacing={3}>
                                    <Grid item xs={9}>
                                        <Select fullWidth value={selectedPaymentMethod} onChange={handlePaymentMethodChange}>

                                        </Select>
                                    </Grid>
                                    <br></br><br></br>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Grid item xs={3}>
                                        <Link to='/add-payment-method'><Button variant='contained'>Add a payment method</Button></Link>
                                    </Grid>
                                </Grid> */}
                                <br></br>
                                <Button variant='contained' color='success' onClick={() => createSubscription(price.id)}>
                                    Subscribe
                                </Button>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </>
    );
}

export default PricingForm;