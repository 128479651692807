import React, { Component } from 'react';
import { Button, TextField, Grid, Container } from '@mui/material';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import httpClient from '../httpClient';
import { baseURL } from '../config/constants/url';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter the email address associated with your account.')
    .required('Please enter the email address associated with your account.')
});

class ForgotPasswordRequestForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      changeRequested: false
    }
  }

  handleSubmit = (values, {
    props = this.props,
    setSubmitting
  }) => {

    // validate
    // if (values.email === undefined || values.email === "") {
    //   alert("Please enter a valid email address.")
    // }

    setSubmitting(true);
    httpClient.post(
      new URL('/user/forget-password', baseURL),
      values
    ).then(res => {
      setSubmitting(false);
      this.setState({
        changeRequested: true
        // revData: res.data.revData,
      });
    }).catch();
  }

  render() {

    if (this.state.changeRequested) {
      return (
        <>
          <p>An email with instructions to reset your password has been sent to you. Please check your inbox and follow the directions to reset your password.</p>
          <p>If you do not see the email, please check your Spam/Junk folder before requesting another reset.</p>
          <Link to='/login'>
            <Button variant='contained'>Return to login page</Button>
          </Link>
        </>
      )


    } else {
      return (
        <Formik
          initialValues={{
            email: ""
          }}
          validationSchema={ValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Container>
                <Grid container spacing={2}>


                  <Grid item xs={12}>

                    <h2>Reset Password</h2>
                  </Grid>


                  {/* <label htmlFor='email'>Email Address</label> */}
                  {/* <Field name="email"
                      render={({field, form}) => (
                        <TextField
                          name={"email"}
                          error={Boolean(errors.email && touched.email)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      )}
                    /> */}
                  <Grid item xs={12}>

                    <TextField

                      id={"email"}
                      name={"email"}
                      label="Email Address"
                      error={Boolean(errors.email && touched.email)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      required
                      type="email"
                    />
                  </Grid>

                  <br />
                  <Grid item>

                    <Button variant="contained" disabled={isSubmitting} type="submit">Submit</Button>
                  </Grid>

                </Grid>
              </Container>
            </form>
          )}
        </Formik>
      )
    }
  }
}

export default ForgotPasswordRequestForm;
