import React from 'react';
import { 
    Button, 
    Divider,
    Grid, 
    Paper,
    Typography 
} from '@mui/material';

import EditMaintenancePlan from '../components/EditMaintenancePlan';
import MaintenancePlanDisplay from './MaintenancePlanDisplay';

const MaintenancePlanPanel = (props) => {
    const asset = props.asset;
    const updateAll = props.updateAll;

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <Paper elevation={3} sx={{padding: 3}}>
                        <Typography variant="h4" align="center" gutterBottom>Maintenance Plan</Typography>
                        <Divider sx={{marginBottom: 2}}/>
                        <MaintenancePlanDisplay asset={asset} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Paper elevation={3} sx={{padding: 3}}>
                        <EditMaintenancePlan asset={asset} updateAll={updateAll} user={props.user} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default MaintenancePlanPanel;