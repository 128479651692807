import React, {useState} from 'react';
import {
    Container,
    Typography
} from '@mui/material';
import OrganizationEditForm from '../components/OrganizationEditForm';
import UserSignupForm from '../components/UserSignupForm';

import httpClient from '../httpClient';

// NOTE: steps are directly hardcoded rather than iterating through this list
// const steps = ['organization', 'user', 'complete'];

const OnboardingFlow = (props) => {
    const [step, setStep] = useState('organization');
    const [organization, setOrganization] = useState(null);
    const [user, setUser] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const handleFinalSubmit = (values) => {

        // defensive check for bad state
        if (
            !organization ||
            !values
        ) {
            console.log("Bad state in handleFinalSubmit");
            alert("There was an error creating the organization. Please check your information and try again.");
            return;
        }

        // first sign up user
        httpClient.signUp(values).then((response) => {
            if (!response) {
                console.log("Bad response from user creation");
                console.log(response);
                alert("There was an error creating the user. Please check your information and try again.");
                return;
            }
            setSubmitted(true);
            setUser(response);

            // then create the organization
            httpClient.post('/organization/', organization).then((response) => {
                // TODO: check response for success
                console.log("Organization created successfully");
                console.log(response);
                // redirect to main page
                window.location.href = '/dashboard';
            }).catch((reason) => {
                alert("There was an error creating the organization. Please check your information and try again.");
                console.log(reason);
                window.location.href = '/';
                return;
            })

        }).catch((reason) => {
            alert("There was an error creating the user account. Please check your information and try again.");
            console.log(reason);
            window.location.href = '/';
            return;
        })


        // httpClient.post('/organization/create', organization).then((response) => {
        //     httpClient.post('/user/create', user).then((response) => {
        //         setSubmitted(true);

        //     }).catch((reason) => {
        //         alert("There was an error creating the user. Please check your information and try again.");
        //         console.log(reason);
        //     })
        // }).catch((reason) => {
        //     alert("There was an error creating the organization. Please check your information and try again.");
        //     console.log(reason);
        // })
    }

    const handleOrganizationSubmit = (values) => {
        // store values
        setOrganization(values);

        // move to next step
        setStep('user');
    }

    const handleUserSubmit = (values) => {
        // store values
        setUser(values);

        // move to next step
        setStep('complete');

        // submit to API server
        handleFinalSubmit(values);
    }

    const handleUserBack = (values) => {
        setUser(values);
        setStep('organization');
    }

    switch (step) {
        case 'organization':
            return (
                <>
                    <Typography variant="h4">Step 1: Create an Organization Account</Typography>
                    <OrganizationEditForm onSubmit={handleOrganizationSubmit} organization={organization}/>
                </>
            );
        case 'user':
            return (
                <>
                    <Typography variant="h4">Step 2: Create Your User Account</Typography>
                    <UserSignupForm onSubmit={handleUserSubmit} onBackButton={handleUserBack} user={user} />
                </>
            );

        case 'complete':
            return (
                <>
                    <Typography variant="h4">Submitting...</Typography>
                    <Typography variant="body1">
                        Your organization and user accounts are being created. You will be redirected when the process is complete.
                    </Typography>
                </>
            );
        default:
            return <Typography variant="body1">
                There was an error. Please close this tab, go to <a href="https://tracmile.com">tracmile.com</a> and try again.
            </Typography>
    }

}

const OrganizationSignupView = (props) => {


    // const onSubmit = (values) => {
    //     httpClient.post('/organization/create', values).then((response) => {
    //         props.history.push('/admin/organizations');
    //     }).catch((reason) => {
    //         alert("There was an error creating the organization. Please check your information and try again.");
    //         console.log(reason);
    //     })
    // }

    return (
        <Container>
            <OnboardingFlow />
        </Container>
    )
}

export default OrganizationSignupView;