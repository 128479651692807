import { RiceBowlTwoTone } from '@mui/icons-material';
import { baseURL } from './config/constants/url';
import httpClient from './httpClient';

const inchesPerMile = 63360.0;

export function revsToMiles(revs, revsPerMile) {
  return 1.0 * revs / revsPerMile;
}

export function diameterToRevsPerMile(diameter) {

  return inchesPerMile / (Math.PI * diameter);
}

export function revsPerMileToDiameter(revsPerMile) {
  return inchesPerMile / (Math.PI * revsPerMile);
}


export function reverseGeocodeCall(location, callback) {
  if (!location) {
  return;
  }
  if (location.streetAddress) {
    return callback(location.streetAddress);
  }

  const url = new URL('/location/streetaddress?locationID=' + location._id, baseURL);

  httpClient.get(
    url,
    // params
  ).then((response) => {
    return callback(response.data.body.streetLocation);
  }).catch((reason) => {
    console.log("Geocode call failed:");
    console.log(reason);
    return callback();
  });
}

/* Helper functions for calculating tire revolutions per mile from the tire size designation. */

const MM_PER_INCH = 25.4;
const INCHES_PER_MILE = 63360.0;


/**
 * Given a tire size designation (for example, "T295/75R22.5"), output the calculated revolutions per mile.
 *
 * @param {String} tireDesignationString
 * @return {Number} The tire's revolutions per mile. Will be 'undefined' if the tire designation could not be parsed.
 */
export function calculateTireRevsPerMile(tireDesignationString) {
    const sizeInfo = parseTireDesignation(tireDesignationString);
    if (sizeInfo === undefined) {
        console.log("Could not parse tire size designation");
        return;
    }
    const sidewallHeightMM = sizeInfo.nominalSectionWidth * (sizeInfo.aspectRatio / 100.0);
    const sidewallHeightInches = sidewallHeightMM / MM_PER_INCH;
    const tireDiameterInches = (2.0 * sidewallHeightInches) + sizeInfo.wheelDiameter;
    const tireCircumferenceInches = tireDiameterInches * Math.PI;
    const revsPerMile = INCHES_PER_MILE / tireCircumferenceInches;
    return revsPerMile;
}


/**
 * Parses and extracts the values contained in a tire size designation string. Leading character (application) may be present or omitted.
 * For example, both "T295/75R22.5" and "295/75R22.5" would be accepted.
 *
 * @param {String} tireDesignationString
 * @return {Object}: { nominalSectionWidth, aspectRatio, wheelDiameter }
 */
export function parseTireDesignation(tireDesignationString) {

    const regex = /(P|PT|LT|ST|T|)(?<nominalSectionWidth>\d{1,3}(?:\.\d)?)(?:[/X-\s](?<aspectRatio>\d{1,2}(?:\.\d)?)?)?([RBD])(?<wheelDiameter>\d{1,3}(?:\.\d)?)/;
    let nominalSectionWidth, aspectRatio, wheelDiameter;
    try {
        ({ nominalSectionWidth, aspectRatio, wheelDiameter } = regex.exec(tireDesignationString).groups);
    } catch (error) {
        console.log("Error parsing tire size designation:");
        console.log(error);
        return;
    }
    if (
        nominalSectionWidth === undefined ||
        aspectRatio === undefined ||
        wheelDiameter === undefined
    ) {
        console.log("Missing tire size value");
        return;
    }
    return {
        nominalSectionWidth: Number(nominalSectionWidth),
        aspectRatio: Number(aspectRatio),
        wheelDiameter: Number(wheelDiameter),
    }
}
