import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    TextField,
} from '@mui/material';

const OrganizationSignupView = (props) => {
    const defaultValues = {
        name: '',
        primaryContactName: '',
        // primaryEmail: '',
        billingAddress: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            postalCode: '',
            country: 'US'
        },
        phoneNumber: '',
    }

    const validationSchema = Yup.object({
        name: Yup.string().required('Organization name is required'),
        primaryContactName: Yup.string().required('Primary contact name is required'),
        // primaryEmail: Yup.string().email('Invalid email address').required('Primary email is required'),
        billingAddress: Yup.object({
            line1: Yup.string().required('Address line 1 is required'),
            line2: Yup.string().optional(),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            postalCode: Yup.string().required('Postal code is required'),
        }),
        phoneNumber: Yup.string().required('Phone number is required'),
    });

    const formik = useFormik({
        initialValues: props.organization || defaultValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.onSubmit(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box component={Container}>

                <Typography variant='h6' marginTop="10px">Organization Name</Typography>
                <TextField
                    margin='normal'
                    fullWidth
                    id="name"
                    name="name"
                    autoComplete='organization'
                    label="Organization Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <br/>


                <Typography variant='h6' marginTop="10px">Organization Primary Contact</Typography>

                <TextField
                    margin='normal'
                    fullWidth
                    id="primaryContactName"
                    name="primaryContactName"
                    label="Primary Contact Name"
                    autoComplete='name'
                    value={formik.values.primaryContactName}
                    onChange={formik.handleChange}
                    error={formik.touched.primaryContactName && Boolean(formik.errors.primaryContactName)}
                    helperText={formik.touched.primaryContactName && formik.errors.primaryContactName}
                />

                {/* <TextField
                    margin='normal'
                    fullWidth
                    type='email'
                    id="primaryEmail"
                    name="primaryEmail"
                    label="Primary Contact Email"
                    autoComplete='email'
                    value={formik.values.primaryEmail}
                    onChange={formik.handleChange}
                    error={formik.touched.primaryEmail && Boolean(formik.errors.primaryEmail)}
                    helperText={formik.touched.primaryEmail && formik.errors.primaryEmail}
                /> */}
                <TextField 
                    margin='normal'
                    fullWidth
                    type='tel'
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Organization Contact Phone Number"
                    autoComplete='tel'
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />

                <Typography variant='h6' marginTop="10px">Organization Billing Information</Typography>
                <TextField
                    margin='normal'
                    fullWidth
                    id='billingAddress.line1'
                    name='billingAddress.line1'
                    label='Address Line 1'
                    autoComplete='address-line1'
                    value={formik.values.billingAddress.line1}
                    onChange={formik.handleChange}
                    error={formik.touched['billingAddress.line1'] && Boolean(formik.errors['billingAddress.line1'])}
                    helperText={formik.touched['billingAddress.line1'] && formik.errors['billingAddress.line1']}
                />
                <br/>
                <TextField
                    margin='normal'
                    fullWidth
                    id='billingAddress.line2'
                    name='billingAddress.line2'
                    label='Address Line 2'
                    autoComplete='address-line2'
                    value={formik.values.billingAddress.line2}
                    onChange={formik.handleChange}
                    error={formik.touched['billingAddress.line2'] && Boolean(formik.errors['billingAddress.line2'])}
                    helperText={formik.touched['billingAddress.line2'] && formik.errors['billingAddress.line2']}
                />
                
                <Grid container spacing={3} marginTop='0px'>
                    <Grid item xs={12} md={6}>
                        <TextField
                            
                            fullWidth
                            id='billingAddress.city'
                            name='billingAddress.city'
                            label='City'
                            autoComplete='address-level2'
                            value={formik.values.billingAddress.city}
                            onChange={formik.handleChange}
                            error={formik.touched['billingAddress.city'] && Boolean(formik.errors['billingAddress.city'])}
                            helperText={formik.touched['billingAddress.city'] && formik.errors['billingAddress.city']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            
                            fullWidth
                            id='billingAddress.state'
                            name='billingAddress.state'
                            label='State'
                            autoComplete='address-level1'
                            value={formik.values.billingAddress.state}
                            onChange={formik.handleChange}
                            error={formik.touched['billingAddress.state'] && Boolean(formik.errors['billingAddress.state'])}
                            helperText={formik.touched['billingAddress.state'] && formik.errors['billingAddress.state']}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            
                            fullWidth
                            id='billingAddress.postalCode'
                            name='billingAddress.postalCode'
                            label='Postal/ZIP Code'
                            autoComplete='postal-code'
                            value={formik.values.billingAddress.postalCode}
                            onChange={formik.handleChange}
                            error={formik.touched['billingAddress.postalCode'] && Boolean(formik.errors['billingAddress.postalCode'])}
                            helperText={formik.touched['billingAddress.postalCode'] && formik.errors['billingAddress.postalCode']}
                        />
                    </Grid>
                </Grid>

                {/* TODO: Add country dropdown, to be populated from DB */}

                {/* <Typography variant='h6' marginTop="10px">Organization Contact Information</Typography> */}
                
                <br/><br/>
                {/* <Button variant='contained' color='primary' type='submit'>Save</Button> */}
                <Button variant="contained" color="primary" type="submit">Next</Button>
            </Box>
        </form>
    );
}

export default OrganizationSignupView;