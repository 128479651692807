import React from 'react';

const ChatBot = (props) => {
    return (
        <>
            {/* Embed the chatbot here */}
            <div>CHATBOT PLACEHOLDER</div>
        </>
    )
}

export default ChatBot;