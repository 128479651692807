import React from 'react';
// import { Card, CardTitle, CardText } from 'material-ui/Card';
import Card from 'react-bootstrap/Card';
import Auth from '../modules/Auth';
import { Link } from 'react-router-dom';

class HomePage extends React.Component {

  // componentDidMount() {
  //   // update authenticated state on logout
  //   this.props.toggleAuthenticateStatus()
  // }

  render() {
    return (
      <Card className="container">
        <Card.Title>Home Page</Card.Title>
        {Auth.isUserAuthenticated() ? (
          <div>
            <Card.Text style={{ fontSize: '16px', color: 'green' }}>Welcome! You are logged in.</Card.Text>
            {/* <Button variant="primary" href='/devices' component={}>Go to Dashboard</Button> */}
            <Link to="/assets" className="btn btn-primary">Go to Dashboard</Link>
          </div>

        ) : (
          <Card.Text style={{ fontSize: '16px', color: 'green' }}>You are not logged in.</Card.Text>
        )}
      </Card>
    )
  }
};

export default HomePage;