
import React from 'react';
import {
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

import { NavLink, useLocation } from "react-router-dom";


export default function AccountInfoButton(props) {

  let location = useLocation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const menuItems =[
    {
      img: "/profileicon.svg",
      text: "My Profile",
      url: "/profile",
    },
    {
      img: "/helpicon.svg",
      text: "Help & Support",
      url: "/help",
    },
  
  ]
  
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // setState({ ...state, drawerOpen: open });
    setDrawerOpen(open);
  };

  return <React.Fragment>
    <IconButton
      color="inherit"
      fontSize="large"
      onClick={toggleDrawer(!drawerOpen)}
      sx={{ backgroundColor: 'white', color: '#1462ac' }}
    >
      <Badge badgeContent={0} color="secondary">
        <AccountCircle />
      </Badge>
      <Typography variant="caption">&nbsp;Account</Typography>
    </IconButton>
    <Drawer
      sx={{flexGrow: 1}}
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
    >
      {/* <List>
        <ListItem button href='/logout'>Log Out</ListItem>
      </List> */}
      <Box sx={{ overflow: "auto" }}>
        <p style={{
          height: '64px'
        }}>1</p>
        <List>
        {menuItems.map((item) => (
          <ListItem button
              selected={item.url === location.pathname}
              key={item.url}
              component={NavLink}
              to={item.url}
              onClick={toggleDrawer(false)}
              style={{
                borderBottomColor: "#e0e0e0",
                borderBottomWidth: "thin",
                borderBottomStyle: "solid",
              }}>
            <ListItemIcon>
                <img src={item.img} alt={item.text}></img>
              </ListItemIcon>
              <ListItemText>{item.text}</ListItemText>
             {/* <button variant ="" alt= {item.url}>
                {item.text}
              </button>*/}
            </ListItem>
          ))}
          
        </List>
        <List>
          <ListItem>
            <Button variant='contained' href='/logout'>
              Log Out
            </Button>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  </React.Fragment>
};
