import React from 'react';
import {
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const daysFromNow = (date) => {
    if (!date) {
        return null;
    }
    const now = dayjs();
    const daysDelta = dayjs(date).diff(now, 'day');
    if (daysDelta < 0) {
        return 'overdue by ' + Math.abs(daysDelta) + ' days';
    }
    return daysDelta + ' days from now';
}

const MaintenancePlanDisplay = (props) => {
    const asset = props.asset;

    let maintenanceLastMiles = 'None - assuming zero';
    if (asset.maintenanceLastMiles !== undefined && asset.maintenanceLastMiles !== null) {
        maintenanceLastMiles = asset.maintenanceLastMiles.toFixed(1);
    }
    let milesSinceLastMaint = asset.lifetimeMileage.toFixed(1);
    let calcMilesSinceLastMaint = asset.lifetimeMileage;
    if (asset.milesSinceLastMaint !== undefined && asset.milesSinceLastMaint !== null) {
        milesSinceLastMaint = asset.milesSinceLastMaint.toFixed(1);
        calcMilesSinceLastMaint = asset.milesSinceLastMaint;
    }
    let milesUntilNextMaint = '-';
    if (asset.milesUntilNextMaint !== undefined && asset.milesUntilNextMaint !== null) {
        milesUntilNextMaint = asset.milesUntilNextMaint.toFixed(1);
    } else if (asset.maintenanceMileageInterval) {
        milesUntilNextMaint = asset.maintenanceMileageInterval - calcMilesSinceLastMaint;
        milesUntilNextMaint = milesUntilNextMaint.toFixed(1);
    }

    let maintenanceLastDate = '-';
    if (asset.maintenanceLastDate !== undefined && asset.maintenanceLastDate !== null) {
        maintenanceLastDate = new Date(asset.maintenanceLastDate).toLocaleDateString();
    }
    let timeSinceLastMaint = '-';
    if (asset.timeSinceLastMaint !== undefined && asset.timeSinceLastMaint !== null) {
        timeSinceLastMaint = asset.timeSinceLastMaint + ' days';
    }
    let projectedNextMaintDate = '-';
    let timeToMaint = '-';
    if (asset.projectedNextMaintDate !== undefined && asset.projectedNextMaintDate !== null) {
        projectedNextMaintDate = new Date(asset.projectedNextMaintDate).toLocaleDateString();
        timeToMaint = daysFromNow(asset.projectedNextMaintDate);
    }

    return (
        <Stack spacing={2}>
            { asset.maintTimeIntervalEnabled &&
                <>
                    <Typography variant="h6">Time-based Maintenance</Typography>

                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='left'>Last maintenance:</TableCell>
                                    <TableCell align='right'>{maintenanceLastDate}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left'>Time since last maintenance:</TableCell>
                                    <TableCell align='right'>{timeSinceLastMaint}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left'>Maintenance due on:</TableCell>
                                    <TableCell align='right'>{projectedNextMaintDate}<br/>{timeToMaint}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* <Typography>Last maintenance: {maintenanceLastDate}</Typography>
                    <Typography>Time since last maintenance: {timeSinceLastMaint}</Typography>
                    <Typography gutterBottom>Projected next maintenance: {projectedNextMaintDate}</Typography> */}
                </>
            }
            { asset.maintMileageIntervalEnabled &&
                <>
                    <Typography variant="h6">Mileage-based Maintenance</Typography>

                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='left'>Last maintenance:</TableCell>
                                    <TableCell align='right'>{maintenanceLastMiles} miles</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left'>Miles since last maintenance:</TableCell>
                                    <TableCell align='right'>{milesSinceLastMaint} miles</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='left'>Miles until next maintenance:</TableCell>
                                    <TableCell align='right'>{milesUntilNextMaint} miles</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* <Typography>Last maintenance: {maintenanceLastMiles} miles</Typography>
                    <Typography>Miles since last maintenance: {milesSinceLastMaint} miles</Typography>
                    <Typography gutterBottom>Miles until next maintenance: {milesUntilNextMaint} miles</Typography> */}
                </>
            }

            { !asset.maintMileageIntervalEnabled && !asset.maintTimeIntervalEnabled &&
                <Typography>No maintenance plan set</Typography>
            }
        </Stack>
    )
}

export default MaintenancePlanDisplay;