import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import { IconButton, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import AccountInfoButton from "./AccountInfoButton";
import NavBarDrawer from "./NavBarDrawer";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "white",
  color: "black",
}));

function VRAppBar(props) {
  // const [auth, setAuth] = React.useState(props.auth);
  const [open, setOpen] = React.useState(true);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // const applyAuth = (auth) => {
  //   setAuth(auth);
  // }

  return (
    <>
      <AppBar
        // position="fixed"
        sx={{
          // zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          zIndex: 1201,
        }}
      >
        <Toolbar>
          <IconButton
            // color="default"
            aria-label="open drawer"
            edge="start"
            // variant="outlined"
            onClick={handleDrawerToggle}
            sx={{
              verticalAlign: "top",
              mr: 2,
              padding: 1,
              display: { sm: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            {/* <Box sx={{display: {xs: "none", sm: "inline"}}}>
            <img src='/vr_logo_blue.png' alt='Veeder-Root logo' />&nbsp;&nbsp;&nbsp;
          </Box> */}
            <img
              src="/TracMile_Logo - Full Color w veeder root.png"
              alt="TRACMILE"
              style={{ height: "58px" }}
            ></img>
          </Link>
          {/* <Typography variant="h5" noWrap component="h1" sx={{ flexGrow: 1 }}>
          &nbsp;TRACMILE&trade; <span style={{ color: '#666' }}><small><small>ASSET TRACKING SYSTEM</small></small></span>
        </Typography> */}
          <Typography variant="p" component="div" sx={{ flexGrow: 1 }}>
            &nbsp;
          </Typography>
          {/* TODO: Introduce (element just needs functionality) */}
          {/* {props.auth ? <NotificationButton user={props.auth} /> : <span></span>} */}
          {props.auth ? <AccountInfoButton user={props.auth} /> : <span></span>}
        </Toolbar>
      </AppBar>

      <NavBarDrawer
        user={props.auth}
        open={open}
        handleDrawerToggle={handleDrawerToggle}
      />
    </>
  );
}

export default VRAppBar;
