import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
// import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import DirectionsIcon from '@mui/icons-material/Directions';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { Link } from 'react-router-dom';
import React, {Component, useState, useEffect} from 'react';

// import axios from 'axios';

import styles from './devicelist.module.css';

// import Auth from '../modules/Auth';
// import {revsToMiles} from './../utils';
// import DeviceMap from '../components/DeviceMap';

import {baseURL} from '../config/constants/url';
import httpClient from '../httpClient';



const DeviceSearchBar = (props) => {

  return (
    <div className="searchbar">
      <Form.Control
        id="searchField"
        type="search"
        onChange={props.searchInputHandler}
        placeholder="Find a device..."
        style={{
          width: '25%'
        }}
      />
    </div>
  )
}

function AlertElement(props) {
  if (!props.alertCount) {
    // return OK
    return (
      <div style={{color: 'green'}}>
        <CheckCircleOutlineIcon fontSize='large' />
        <p>No active alerts</p>
      </div>
    )
  } else {
    return (
      <div style={{color: 'red'}}>
        <WarningAmberIcon fontSize='large' />
        <p><a href='/alerts'>{props.alertCount} active alerts</a></p>
      </div>
    )
  }
}

function DeviceCard(props) {

  const [tripHistory, setTripHistory] = useState(null);
  const cardID = 'dbelement-' + props.deviceID;

  useEffect(() => {
    if (!tripHistory) {
      httpClient.get(
        new URL('/device/trip-history/' + props.deviceID, baseURL),
      ).then((response) => {
        setTripHistory(response.data.tripHistory);
      });
    }
  });

  let alertCount = (props.numActiveAlerts ? props.numActiveAlerts : 0);

  let oldestHistoryText = 'No trip history available';
  if (tripHistory?.length) {
    const oldestDate = tripHistory[tripHistory.length - 1].ts;
    oldestHistoryText = 'History available since ' + new Date(oldestDate).toLocaleDateString();
  }

  let huboName = props.name;
  if (!huboName || huboName === '') {
    huboName = props.deviceID;
  }

  return (
    <>
      <Col sm={12}>
        <Card id={cardID} className={styles.DeviceCard}>
          <Card.Body>
            <Row>
              <Col md={4}>
                <p>DEVICE NAME</p>
                <Link to={'/devicedetail/' + props.deviceID}>
                  <h3>{huboName}</h3>
                </Link>
                <p><i>{props.deviceID}</i></p>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col sm={6}>
                    {/* Alerts */}
                    <p>ALERTS</p>
                    <AlertElement alertCount={alertCount}/>
                  </Col>
                  <Col sm={6}>
                    {/* Link to location history? */}
                    {/* <DirectionsIcon fontSize='large'></DirectionsIcon> */}
                    <p>ODOMETER READING</p>
                    <h3>{props.device.currentMileage.toFixed(1)} mi</h3>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Link to={'/triphistory/' + props.deviceID}>
                  {/* <p><LocationOnIcon /> Trip History:</p> */}
                  <p>TRIP HISTORY</p>
                  <h4><small>{oldestHistoryText}</small></h4>
                </Link>
              </Col>
              {/*
              <Col>
                <p>NAME</p>
                <h3>{props.name}</h3>
              </Col>
              <Col>
                <p>CUMULATIVE DISTANCE</p>
                <h3>{props.cumDistance.toFixed(1)} mi</h3>
              </Col>
              <Col>
                <p>AVERAGE DAILY MILEAGE</p>
                <h3>{props.dailyMileage}</h3>
              </Col>
              <Col>
                <p>PERCENT IDLE TIME</p>
                <h3>{props.percentIdle}</h3>
              </Col>
              <Col>
                <p>ACTIVE ALERTS</p>
                <h3>{alertCount}</h3>
              </Col> */}

            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}

// function DeviceList(props) {
//   const devices = props.devices;
//   const cards = devices.map((device) =>
//     <DeviceCard device={device}/>
//   );
//   return <CardDeck>
//     {cards}
//   </CardDeck>
// }

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      isLoading: true,
      searchFilter: ""
    };
  }

  componentDidMount() {
    let deviceURL = new URL('/device/list-by-user/' + this.props.user._id, baseURL);
    // TODO: use serverside security here
    if (this.props.user?.admin) {
      deviceURL = new URL('/device/', baseURL);
    }

    const updateDevices = () => {
       httpClient.get(
        deviceURL
      ).then(response => {
        // let devices = this.state.devices;
        // // data object is nested for some reason
        // if (response.data.data) {
        //   devices = response.data.data;
        // }
        this.setState({
          isLoading: false,
          devices: response.data.devices
        })
      }).catch(reason => {
        // alert("There was an error getting device information.")
        console.log("Error getting device information");
        console.log(reason);
      });
    }
    updateDevices();
    // this.interval = setInterval(updateDevices, 30000);


  }

  componentWillUnmount() {
    // clearInterval(this.interval);
  }

  render() {

    const spinnerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'inherit',
      textAlign: 'center'
    };

    const searchInputHandler = (e) => {
      this.setState({searchFilter: e.target.value});
    };

    return (this.state.loading) ?
    (
      <div className='loading-div'>
        <div style={spinnerStyle}>
          <Spinner animation='border' size='lg'/>
        </div>
      </div>
    ) :
    (
      <div>
        <Typography variant='h4'>All Devices</Typography>
        {/* <DeviceMap devices={this.state.devices} /> */}

        <br />
        <div style={{
          width: '100%',
          paddingLeft: '15px',
          paddingRight: '15px',
          marginBottom: '40px',
          display: 'block'
        }}>
            <Button type='button' variant='contained' href='/linkdevice' style={{float: 'right'}}>Link a new device</Button>
            <DeviceSearchBar searchInputHandler={searchInputHandler} style={{float: 'left', marginTop: '0px'}}/>
        </div>

        <div>
          {(this.state.devices.length) ?
            this.state.devices.map((device) => {
              // let cumDistance = revsToMiles(device.mostRecentData.revs, device.revsPerMile);
              if (
                this.state.searchFilter.length && (
                  !device.deviceID?.toLowerCase().includes(this.state.searchFilter.toLowerCase()) &&
                  !device.name?.toLowerCase().includes(this.state.searchFilter.toLowerCase()) &&
                  !device.description?.toLowerCase().includes(this.state.searchFilter.toLowerCase())
                )
              ) {
                // this device does not fit the search filter
                return "";
              } else {
              }
              let cumDistance = device.currentMileage;
              return (
                <DeviceCard device={device} name={device.name} key={device.deviceID} deviceID={device.deviceID} cumDistance={cumDistance} numActiveAlerts={device.alerts.length}/>
              )
            })
            : <p>No devices to display</p>
          }
        </div>
      </div>
    )
  }
}

export default DeviceList;
