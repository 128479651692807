import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
// import {BsCheck} from 'react-icons/bs'; // checkmark icon
import CheckIcon from '@mui/icons-material/Check';

import httpClient from '../httpClient';

// function generateDAHTML(user) {
//   let accounts = '';
//   for (let i = 0; i < user.dataAccounts.length; i++) {
//     accounts += user.dataAccounts[i];
//     if (i !== user.dataAccounts.length - 1) {
//       accounts += ', ';
//     }
//   }
//   return accounts;
// }

function UserDevices(props) {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    httpClient.get('/device/list-by-user/' + props.user._id).then((response) => {
      setDevices(response.data.devices);
    }).catch((reason) => {
      console.log("Error when getting devices for user " + props.user.email);
      console.log(reason);
    })
  }, [props.user._id, props.user.email]);

  return (
    <>
      {devices.map(device => { return (
        <p key={"userdevice-" + device._id}>{device.deviceID}</p>
      )})}
    </>
  )
}

function UserAssets(props) {
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    httpClient.get('/asset/list-by-user/' + props.user._id).then((response) => {
      setAssets(response.data.assets);
    }).catch((reason) => {
      console.log("Error when getting assets for user " + props.user.email);
      console.log(reason);
    })
  }, [props.user._id, props.user.email]);

  return (
    <>
      {assets.map(asset => { return (
        <p key={"userasset-" + asset._id}>{asset._id} - {asset.name}</p>
      )})}
    </>
  )

}

export default function AdminUserList(props) {

  const users = props.users;

  // const dataAccountHTML = generateDAHTML()
  return <Table bordered>
    <thead>
      <tr>
        <th>Username</th>
        <th>Full Name</th>
        <th>Email Address</th>
        <th>Owned Assets</th>
        <th>Owned Devices</th>
        <th>TracMile Admin?</th>
      </tr>
    </thead>
    <tbody>
      { users.map(user => { return (
        <tr key={user.username}>
          <td><a href={'/userdetail/' + user._id}>{user.username}</a></td>
          <td>{user.fullName}</td>
          <td>{user.email}</td>
          <td><UserAssets user={user}/></td>
          <td><UserDevices user={user}/></td>
          <td>{(user.admin) ? <CheckIcon/> : ''}</td>
        </tr>
      )}) }
    </tbody>
  </Table>
}