import React from 'react';

import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import httpClient from '../httpClient';

/*
    This is the form used to look up revs per mile from the tire information database

    requiredProps:
        assetId - the _id of the asset being configured
 */
const TireLookupForm = (props) => {
    const [brands, setBrands] = React.useState([]);
    const [styles, setStyles] = React.useState([]);
    const [sizes, setSizes] = React.useState([]);

    const [revsPerMile, setRevsPerMile] = React.useState();

    const [selectedBrand, setSelectedBrand] = React.useState('');
    const [selectedStyle, setSelectedStyle] = React.useState('');
    const [selectedSize, setSelectedSize] = React.useState('');

    const navigate = useNavigate();

    React.useEffect(() => {
        // get initial brands list
        httpClient.get('/tire-information/brands').then((response) => {
            setBrands(response.data.brands);
            setSelectedStyle('');
            setSelectedSize('');
            setRevsPerMile();
        }).catch((reason) => {
            console.log("Error getting brands list:");
            console.log(reason);
        })
    }, [])

    const handleChangeBrand = (event) => {
        // get Styles list
        const brand = event.target.value;
        setSelectedBrand(brand);
        httpClient.get('/tire-information/styles?brand=' + brand).then(response => {
            setStyles(response.data.styles);
            setSelectedSize('');
            setRevsPerMile();
        }).catch(reason => {
            console.log("Error getting style list:");
            console.log(reason);
        });
    }

    const handleChangeStyle = (event) => {
        // get Sizes list
        const style = event.target.value;
        setSelectedStyle(style);
        httpClient.get('/tire-information/sizes?brand=' + selectedBrand + '&style=' + style).then(response => {
            setSizes(response.data.sizes);
            setRevsPerMile();
        }).catch(reason => {
            console.log("Error getting size list:");
            console.log(reason);
        });
    }

    const handleChangeSize = (event) => {
        const size = event.target.value;
        setSelectedSize(size);
        httpClient.get(
            '/tire-information/tire',
            {
                params: {
                    brand: selectedBrand,
                    style: selectedStyle,
                    size: size
                }
            }
        ).then(response => {
            setRevsPerMile(response.data.tireInfo.revsPerMile);
        }).catch(reason => {
            console.log("Error getting tire info");
            console.log(reason);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // validate
        if (revsPerMile === undefined || isNaN(revsPerMile) || revsPerMile <= 0) {
            alert("Invalid revs per mile value");
            return;
        }
        const updateObject = {
            revsPerMile: revsPerMile
        };
        httpClient.put(
            "/asset/" + props.assetId,
            updateObject
        ).then((response) => {
            // show confirmation
            alert("Tire information saved successfully.");
            // if there is a nextView, navigate to it
            if (props.nextView) {
                navigate(props.nextView);
            }
        }).catch((reason) => {
            console.log("Error submitting revsPerMile from tire lookup form:");
            console.log(reason);
            alert(
                "There was a problem saving the information to the database. " +
                "Please refresh the page and try again."
            );
        });
    }

    return (

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <FormControl fullWidth>
                        <InputLabel id="brandLabel">Tire Brand</InputLabel>
                        <Select
                            labelId="brandLabel"
                            id="brandSelect"
                            value={selectedBrand}
                            onChange={handleChangeBrand}
                            label="Tire Brand"
                            disabled={!brands.length}
                        >
                            {brands.map((style, index) =>
                                <MenuItem value={style} key={"brandchoice-" + index}>{style}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>

                    <FormControl fullWidth>
                        <InputLabel id="styleLabel">Tire Style</InputLabel>
                        <Select
                            labelId="styleLabel"
                            id="styleSelect"
                            value={selectedStyle}
                            onChange={handleChangeStyle}
                            label="Tire Style"
                            disabled={!styles.length}
                        >
                            {styles.map((style, index) =>
                                <MenuItem value={style} key={"stylechoice-" + index}>{style}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>

                    <FormControl fullWidth>
                        <InputLabel id="sizeLabel">Tire Size</InputLabel>
                        <Select
                            labelId="sizeLabel"
                            id="sizeSelect"
                            value={selectedSize}
                            onChange={handleChangeSize}
                            label="Tire Size"
                            disabled={!sizes.length}
                            fullWidth
                        >
                            {sizes.map((style, index) =>
                                <MenuItem value={style} key={"sizechoice-" + index}>{style}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <p>Revolutions per Mile: {revsPerMile}</p>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        variant='contained'
                        disabled={(revsPerMile === undefined || revsPerMile === 0)
                    }>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TireLookupForm;
