import React from 'react';

import dayjs, { Dayjs } from 'dayjs';
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {
    DatePicker,
    LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormik } from 'formik';
import * as yup from 'yup';


import httpClient from '../httpClient';

const EditMaintenancePlan = (props) => {

    const asset = props.asset;

    const validationSchema = yup.object({
        maintenancePlannerIsEnabled: yup.boolean(),
        maintenancePlannerShouldSendReminders: yup.boolean(),
        maintenancePlannerShouldSendAlerts: yup.boolean(),

        maintTimeIntervalEnabled: yup.boolean(),
        maintenanceTimeInterval: yup.number().min(1),
        reminderTimeInterval: yup.number().min(0),
        maintenanceLastDate: yup.date(),

        maintMileageIntervalEnabled: yup.boolean(),
        maintenanceMileageInterval: yup.number().min(1),
        reminderMileageInterval: yup.number().min(0),
        maintenanceLastRevolutions: yup.number().min(0),

        maintenancePlanSendEmails: yup.boolean(),
        maintenancePlanSendSMS: yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            maintenancePlannerIsEnabled: asset.maintenancePlannerIsEnabled,
            maintenancePlannerShouldSendReminders: asset.maintenancePlannerShouldSendReminders,
            maintenancePlannerShouldSendAlerts: asset.maintenancePlannerShouldSendAlerts,

            maintTimeIntervalEnabled: asset.maintTimeIntervalEnabled,
            maintenanceTimeInterval: asset.maintenanceTimeInterval,
            reminderTimeInterval: asset.reminderTimeInterval,
            maintenanceLastDate: asset.maintenanceLastDate,

            maintMileageIntervalEnabled: asset.maintMileageIntervalEnabled,
            maintenanceMileageInterval: asset.maintenanceMileageInterval,
            reminderMileageInterval: asset.reminderMileageInterval,
            maintenanceLastRevolutions: asset.maintenanceLastRevolutions,

            maintenancePlanSendEmails: asset.maintenancePlanSendEmails,
            maintenancePlanSendSMS: asset.maintenancePlanSendSMS,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            httpClient.put(`/asset/${asset._id}`, values)
                .then((response) => {
                    console.log(response);
                    alert('Maintenance plan saved.');
                    props.updateAll();
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    });

    if (!asset) {
        return <Typography variant="h4" component="h1" align="center">No asset selected</Typography>;
    }

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>Edit Maintenance Plan</Typography>
            <Divider sx={{marginBottom: 2}}/>
            <form onSubmit={formik.handleSubmit}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* Maintenance planner is enabled checkbox */}
                    <Stack direction="row" spacing={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="maintenancePlannerIsEnabled"
                                    name="maintenancePlannerIsEnabled"
                                    checked={formik.values.maintenancePlannerIsEnabled}
                                    onChange={(event) => {
                                        formik.setFieldValue('maintenancePlannerIsEnabled', event.target.checked);
                                    }}
                                />
                            }
                            label="Use Maintenance Planner"
                        />
                    </Stack>

                    {formik.values.maintenancePlannerIsEnabled && (
                        <>
                            <Stack spacing={2}>
                                <Typography variant="h6" component="h2">Time Interval</Typography>
                                {/* Time interval enabled checkbox */}
                                <Stack direction="row" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="maintTimeIntervalEnabled"
                                                name="maintTimeIntervalEnabled"
                                                checked={formik.values.maintTimeIntervalEnabled}
                                                onChange={(event) => {
                                                    formik.setFieldValue('maintTimeIntervalEnabled', event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Time-based maintenance interval enabled"
                                    />
                                </Stack>

                                {formik.values.maintTimeIntervalEnabled && (
                                    <>
                                        <TextField
                                            id="maintenanceTimeInterval"
                                            name="maintenanceTimeInterval"
                                            label="Maintenance Interval (days between scheduled maintenance)"
                                            type="number"
                                            value={formik.values.maintenanceTimeInterval}
                                            onChange={formik.handleChange}
                                            error={formik.touched.maintenanceTimeInterval && Boolean(formik.errors.maintenanceTimeInterval)}
                                            helperText={formik.touched.maintenanceTimeInterval && formik.errors.maintenanceTimeInterval}
                                        />
                                        <TextField
                                            id="reminderTimeInterval"
                                            name="reminderTimeInterval"
                                            label="Reminder Interval (days before scheduled maintenance)"
                                            type="number"
                                            value={formik.values.reminderTimeInterval}
                                            onChange={formik.handleChange}
                                            error={formik.touched.reminderTimeInterval && Boolean(formik.errors.reminderTimeInterval)}
                                            helperText={formik.touched.reminderTimeInterval && formik.errors.reminderTimeInterval}
                                        />
                                        <DatePicker
                                            id="maintenanceLastDate"
                                            name="maintenanceLastDate"
                                            label="Last Maintenance Date"
                                            value={dayjs(formik.values.maintenanceLastDate)}
                                            onChange={(date) => formik.setFieldValue('maintenanceLastDate', new Date(date))}
                                            error={formik.touched.maintenanceLastDate && Boolean(formik.errors.maintenanceLastDate)}
                                            helperText={formik.touched.maintenanceLastDate && formik.errors.maintenanceLastDate}
                                        />
                                    </>
                                )}

                                <Typography variant="h6" component="h2">Mileage Interval</Typography>
                                {/* Mileage interval enabled checkbox */}
                                <Stack direction="row" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="maintMileageIntervalEnabled"
                                                name="maintMileageIntervalEnabled"
                                                checked={formik.values.maintMileageIntervalEnabled}
                                                onChange={(event) => {
                                                    formik.setFieldValue('maintMileageIntervalEnabled', event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Mileage-based maintenance interval enabled"
                                    />
                                </Stack>

                                {formik.values.maintMileageIntervalEnabled && (
                                    <>
                                        <TextField
                                            id="maintenanceMileageInterval"
                                            name="maintenanceMileageInterval"
                                            label="Maintenance Interval (miles)"
                                            type="number"
                                            value={formik.values.maintenanceMileageInterval}
                                            onChange={formik.handleChange}
                                            error={formik.touched.maintenanceMileageInterval && Boolean(formik.errors.maintenanceMileageInterval)}
                                            helperText={formik.touched.maintenanceMileageInterval && formik.errors.maintenanceMileageInterval}
                                        />
                                        {/*
                                            revolutions = miles * revsPerMile
                                            miles = revolutions / revsPerMile
                                        */}
                                        <TextField
                                            id="reminderMileageInterval"
                                            name="reminderMileageInterval"
                                            label="Reminder Interval (miles before scheduled maintenance)"
                                            type="number"
                                            value={formik.values.reminderMileageInterval}
                                            onChange={formik.handleChange}
                                            error={formik.touched.reminderMileageInterval && Boolean(formik.errors.reminderMileageInterval)}
                                            helperText={formik.touched.reminderMileageInterval && formik.errors.reminderMileageInterval}
                                        />
                                        <TextField
                                            id="maintenanceLastRevolutions"
                                            name="maintenanceLastRevolutions"
                                            label="Last Maintenance Mileage"
                                            type="number"
                                            value={formik.values.maintenanceLastRevolutions * 1.0 / asset.revsPerMile}
                                            onChange={(event) => formik.setFieldValue('maintenanceLastRevolutions', event.target.value * asset.revsPerMile)}
                                            error={formik.touched.maintenanceLastRevolutions && Boolean(formik.errors.maintenanceLastRevolutions)}
                                            helperText={formik.touched.maintenanceLastRevolutions && formik.errors.maintenanceLastRevolutions}
                                        />
                                    </>
                                )}
                                <Typography variant="h5" component="h2">Alerts and Reminders</Typography>
                                {/* Alerts and reminders checkboxes */}
                                <Stack direction="row" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="maintenancePlanSendEmails"
                                                name="maintenancePlanSendEmails"
                                                checked={formik.values.maintenancePlanSendEmails}
                                                onChange={(event) => {
                                                    formik.setFieldValue('maintenancePlanSendEmails', event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Send Email Notifications"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="maintenancePlanSendSMS"
                                                name="maintenancePlanSendSMS"
                                                checked={formik.values.maintenancePlanSendSMS}
                                                onChange={(event) => {
                                                    formik.setFieldValue('maintenancePlanSendSMS', event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Send SMS Notifications"
                                    />
                                </Stack>
                            </Stack>
                            <Divider sx={{marginBottom: 2}}/>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Save Maintenance Plan
                            </Button>
                        </>
                    )}
                </LocalizationProvider>
            </form>
        </>
    );
}

export default EditMaintenancePlan;